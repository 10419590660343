
import { getCookie, setCookie } from "../../utils/tools";
import { client } from '@/client';
import { defineComponent } from 'vue';
import { ElMessage } from "element-plus";
import { AdminStatus } from "@/shared/types/AdminData";
export default defineComponent ({
  name: "admin-edit",
  data: () => {
    return {
      params: {
        username: "",
        password: "",
        updatedAt: new Date(),
        status: "1",
        id:""
      },
      paramsRules: {
        username: [
          { required: true, message: "请输入用户名", trigger: "blur" },
          {
            min: 2,
            max: 20,
            message: "2到20个字符",
            trigger: "blur",
          },
        ],
        password: [
          { required: true, message: "请输入密码", trigger: "blur" },
          {
            min: 6,
            max: 20,
            message: "6到20个字符",
            trigger: "blur",
          },
        ],
      },
    };
  },
  computed: {},
  async created() {
    this.params.id = this.$route.params.id as string;
    await this.detail();
  },
  methods: {
    // 文章详情
    async detail() {
      try {
        let res = await client.callApi("admin/Detail",{id:parseInt(this.params.id)});
        if (res.isSucc) {
          let data = res.res;
          this.params.status = data.status.toString();
          this.params.username = data.username;
          this.params.password = "";
          this.params.id = data.id.toString();
          this.params.updatedAt = data.updatedAt;
        }
      } catch (error) {
        console.error(error);
      }
    },

    //更新
    async update() {
      try {
        let res = await client.callApi("admin/Update",{
          id:parseInt(this.params.id),
          username:this.params.username,
          password:this.params.password,
          status:parseInt(this.params.status) as AdminStatus,
        });
        if (res.isSucc) {
          if (this.params.username == getCookie("username")) {
            ElMessage({
              message: "密码更新成功,请重新登录^_^",
              type: "success",
            });
            setCookie("token", "");
            this.$router.push({ name: "login-in" });
          } else {
            ElMessage({
              message: "更新成功^_^",
              type: "success",
            });
            this.$router.go(-1);
          }
        }
      } catch (error) {
        console.log(error);
      }
    },

    submit(formName: string) {
      let refs:any = this.$refs[formName];
      if (refs) {
          refs.validate((valid: any) => {
          if (valid) {
            this.update();
          } else {
            console.log("error submit!!");
            return false;
          }
        });
      }
    },
  },
});
